<template>
  <v-app class="advisor-app-embed">
    <v-main :class="$route.params.org_id">
      <slot />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
const $route = useRoute();
const { $sentry } = useNuxtApp();

watch($route, (newRoute) => {
  $sentry.setContext({
    path: newRoute.fullPath,
  });
});

$sentry.setContext({
  path: $route.fullPath,
});
</script>

<style lang="scss">
.v-application {
  background-color: white !important;
}
</style>
